.success-banner {
  width: 100vw;
  z-index: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.success-card {
  z-index: 10;
}

@media only screen and (min-width: 992px) {
  .success-card {
    margin-bottom: auto;
    margin-top: 5rem;
  }
}
