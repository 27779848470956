html {
  height: 100%;
}

* {
  scrollbar-color: $hexcolor_red white;
  scrollbar-width: thin;
}

body,
#root {
  font-family: $font-stack;
  height: 100%;
  color: $color_text;
}

p {
  margin-bottom: 0;
}

.background-body {
  height: 100%;
  overflow-y: auto;
}

.background-beige {
  background-color: #f8f5f1;
}

.background {
  background: url("../../assets/background-app-web.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.logo {
  z-index: 100;
  width: 100%;
  position: absolute;
  max-height: 100px;
  top: -60px;
  left: 0px;

  display: flex;
  justify-content: center;
}

.logo img {
  max-height: 100px;
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(0px 3px 0px rgba(0, 0, 0, 0.2));
}

.card-page {
  min-height: calc(100% - 80px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}

.grecaptcha-badge {
  visibility: hidden;
}

.recaptcha-message {
  font-size: 0.75rem;
  color: $hexcolor_beige_ombre;
}

::-webkit-scrollbar {
  width: 10px;
  padding: 5px;
}

/* Track
::-webkit-scrollbar-track {
  //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
}*/

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba($hexcolor_red, 1);
  //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba($hexcolor_red, 1);
}

.bg {
  &-beige {
    background-color: $color_background;
  }

  &-red {
    background-color: $hexcolor_red;
  }
}
