@import "registration/Registration";

input.form__input--text {
  border: 1px solid $hexcolor_beige_ombre;
}

div.form__input--password {
  border: 1px solid $hexcolor_beige_ombre;
  position: relative;

  .password-input__append {
    margin: -1px -1px -1px 0px;
    color: white;

    button {
      background-color: $hexcolor_red;

      span {
        display: none;
      }

      &::before {
        font-family: "icomoon";
        font-size: 1.25rem;
        color: white;
        content: "\e907";
      }
    }

    &.password-input__append--show {
      button {
        &::before {
          font-family: "icomoon";
          content: "\e905";
        }
      }
    }
  }
}

button.form__button {
  $form-button-height: 1.5rem;
  $form-button-svg-width: calc(#{$form-button-height} * 0.38);

  border: none;
  background-color: $hexcolor_red;
  color: white;

  filter: drop-shadow(0px 2.5px 0px $hexcolor_beige_ombre);

  margin-left: $form-button-svg-width;
  margin-right: $form-button-svg-width;

  width: calc(100% - calc(#{$form-button-svg-width} * 2));

  &:hover {
    background-color: $hexcolor_red_light;

    &::before {
      @include left-button-svg($hexcolor_red_light);
    }

    &::after {
      @include right-button-svg($hexcolor_red_light);
    }
  }

  &:active {
    filter: drop-shadow(0px 0px 0px $hexcolor_beige_ombre);
  }

  &::before,
  &::after {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;

    width: $form-button-svg-width;
  }

  &::before {
    // Scale down slightly to avoid gap between button and svgs.
    left: calc(#{$form-button-svg-width} * -0.99);
    @include left-button-svg($hexcolor_red);
  }

  &::after {
    right: calc(#{$form-button-svg-width} * -0.99);
    @include right-button-svg($hexcolor_red);
  }
}
