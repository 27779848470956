.alert {
  border: none !important;
  @include bevel($bevel_card);
}

.alert-danger {
  background-color: $hexcolor_red_light;
  color: white;
}

.alert-success {
  background-color: $hexcolor_green;
  color: white;
}

.subscription-alert {
  @include padding-vertical(0.5rem);
  background-color: $hexcolor_green;
  font-weight: bold;
  color: white;

  a {
    color: $hexcolor_red;
  }
}
