form label {
  color: $color_text;
}

.input-wrap {
  padding: 1px;
  background: lightgray;
  @include bevel(6px);
}

.form-control {
  line-height: 32px !important;
  border: none;
  @include bevel(5px);

  &:focus {
    border: none !important;
    box-shadow: none !important;
  }
}

.form-control-left-bevel {
  @include half-bevel-left(4px);
}

.form-wrap-left-bevel {
  @include half-bevel-left(5px);
}

.password-field {
  button {
    @include padding-horizontal(5px);
    font-size: 30px;
    display: flex;
    align-items: center;
  }
}

.form-error {
  margin-top: 0.5rem;
  color: $hexcolor-red;
}

.icon-picker {
  background-color: white;
  padding: 0.5rem;

  .icon-square {
    margin: 0.2rem;
    padding: 0.2rem;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &.picked {
      margin: 0;
      border: 0.2rem solid $hexcolor-red;
    }
  }
}

.links-phone {
  position: relative;
  z-index: 10000;
}

.links-button {
  position: relative;
  z-index: 1;
}
