.referred-registration-page {
  display: flex;
  flex-direction: column;

  &__registration {
    margin: 0;
    padding-bottom: 4em;

    &-text,
    &-form {
      padding: 2em;
    }

    &-text {
      padding-top: 6em;

      h1,
      p {
        font-family: $font-stack;
        letter-spacing: normal;
        text-transform: none;
        text-align: left;
        color: $color_background;
      }
    }

    &-form {
      position: relative;

      .affiliate_form_kairos {
        display: none;

        @media only screen and (min-width: 992px) {
          display: block;
          position: absolute;
          transform: scaleX(-1);
          height: 20em;
          z-index: 10;
          bottom: 1em;
          right: 0em;
        }
      }

      @media only screen and (min-width: 992px) {
        padding-right: 6em;
        padding-left: 3em;

        .recaptcha-message {
          padding-right: 3em;
        }

        .registration__terms {
          padding-right: 3em;
        }
      }
    }
  }

  &__information {
    position: relative;
    background-color: white;
    padding: 4em;

    margin: 0;

    color: $color_heading;

    text-align: center;

    .affiliate-images {
      max-width: 100%;
    }

    .col {
      margin-bottom: 2em;
    }

    h4,
    h3 {
      font-family: $font-stack;
      letter-spacing: normal;
    }

    h3 {
      color: $hexcolor_red_bright;
    }

    #icon__bg__section-t {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
