//  Colors  //
//  Palette
$color_background: #f8f5f1;
$color_heading: #3b6466;
$color_text: #903630;
$color_button: #dd4f4b;
$color_button_hover: #f26657;
$color_facebook: #4267b2;
$color_facebook_hover: #5c81cc;

$hexcolor_beige: #f5efe7;
$hexcolor_beige_light: #f8f5f1;
$hexcolor_beige_ombre: #c5c4c1;
$rgbacolor_beige_ombre: rgba(197, 196, 193, 1);

$hexcolor_red: #dd4f4b;
$hexcolor_red_medium: #f26657;
$hexcolor_red_light: #f4806f;
$hexcolor_red_soft: #dd4f4b;
$hexcolor_red_bright: #ef4044;
$rgbacolor_red_light: rgba(244, 128, 111, 1);
$rgbacolor_red_medium: rgba(242, 102, 87, 1);
$rgbacolor_red: rgba(239, 64, 68, 1);

$hexcolor_turquoise: #3b6466;
$hexcolor_turquoise_medium: #629d9b;
$hexcolor_turquoise_light: #9cbcbb;

$hexcolor_green: #c2bf44;
$hexcolor_green_medium: #ded99e;
$hexcolor_green_light: #ece9cb;
$hexcolor_green_dark: #6b9100;
$rgbacolor_green: rgba(194, 191, 68, 1);

$rgbacolor-weekend: rgba(230, 156, 44, 1);

$rgbacolor-routine-0: rgba(230, 156, 44, 1);
$rgbacolor-routine-1: rgba(223, 96, 69, 1);
$rgbacolor-routine-2: rgba(144, 79, 80, 1);
$rgbacolor-routine-3: rgba(36, 79, 85, 1);
