.custom-navbar {
  width: 100%;
  top: 0px;
  z-index: 1000;
  padding: 10px 10px 10px 10px;

  &.app {
    background-color: $hexcolor_red;
  }

  .nav-item {
    .nav-link {
      font-family: "Ander Hedge";
      font-size: larger;
      letter-spacing: 2px;
      color: white !important;
      padding-bottom: 0.25rem;

      &.selected {
        border-bottom: 3px solid white;
      }
    }
  }
}

.header-logo {
  max-height: 50px;

  &-simple {
    max-height: 30px;
  }
}

.language-toggler {
  font-family: Ander Hedge, "Arial";
  letter-spacing: 2.5px;
  cursor: pointer;

  &.color-white {
    color: white;
  }

  &:hover {
    color: $hexcolor_beige_ombre;
  }
}
