.referral-card {
  text-align: center;

  padding: 2em;

  img {
    max-width: 100%;
  }

  input {
    flex-grow: 1;
  }

  .icon-copy {
    cursor: pointer;
  }
}
