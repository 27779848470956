@mixin left-button-svg($color) {
  content: svg-encode(
    "<svg viewBox='0 0 16 56' id='' xmlns='http://www.w3.org/2000/svg' class='btn__svg-bg-left icon' aria-hidden='true'><path fill='" +
      "#{$color}"+"' d='M0 14.933L16 0v56L4 48.533l-4-33.6z'></path></svg>"
  );
}

@mixin right-button-svg($color) {
  content: svg-encode(
    "<svg viewBox='0 0 16 56' id='' xmlns='http://www.w3.org/2000/svg' class='btn__svg-bg-right icon' aria-hidden='true'><path fill='" +
      "#{$color}"+"' d='M16 14.933L0 0v56l12-7.467 4-33.6z'></path></svg>"
  );
}

@function svg-encode($svg) {
  // Encode characters
  $encode: (
    '"': "%22",
    "#": "%23",
    "%": "%25",
    "<": "%3C",
    ">": "%3E",
    "{": "%7B",
    "}": "%7D",
  );

  $string: "";
  $character: "";

  @for $i from 1 through str-length($svg) {
    $character: str-slice($svg, $i, $i);

    @if index(map-keys($encode), $character) {
      $character: map-get($encode, $character);
    }

    $string: $string + $character;
  }

  // Return data URI
  @return url("data:image/svg+xml,#{$string}");
}
