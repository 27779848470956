.billing {
  &-section {
    &-promo {
      height: auto;

      @media only screen and (min-width: 576px) {
        height: 500px;
        max-width: 700px;
      }
    }
  }
}
